import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import Select from 'react-select';
import { Card, Button, OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import CsLineIcons from '../../../cs-line-icons/CsLineIcons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { getAllReturnOrders, returnStatusCount } from '../actions/orderActions';
import { SORTING_ORDER } from '../../../constants/index';

import moment from 'moment';

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from 'reactstrap';
//import {useIntl} from 'react-intl';
import CountUp from 'react-countup';
import OrderExportReport from './OrderExportReport';

const ReturnOrderList = () => {
  const location = useLocation();
  const [isApiCall, setApiCall] = useState(0);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [ActiveFilter, setActiveFilter] = useState(false);

  const dispatch = useDispatch();
  //const { formatMessage: f } = useIntl();
  //const [countryCode, setCountryCode] = useUrlSearchParams({ country: "" });

  const [sortingName, setSortingName] = useState({ id: 'sort.date_asc' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [returnFilterVal, setreturnFilterValue] = useUrlSearchParams({
    returnFilter: 'All',
  });
  const [customActiveTab, setcustomActiveTab] = useState('All');
  const [orderMode, setOrderMode] = useState('All');
  let is_split = 0;
  let currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  let StsFilter = 'All';
  if (returnFilterVal?.returnFilter !== '') {
    StsFilter = returnFilterVal?.returnFilter;
  }

  const splitVal = location?.pathname.split('/');

  useEffect(() => {
    setDataLoading(true);
    if (isApiCall === 0) {
      getAllReturnOrders({
        params: {
          country: currentCountry,
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
          sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
          search_qry: searchValue.search,
          status: customActiveTab,
          // status: StsFilter,
          orderBy: 'id',
        },
        dispatch,
      });
      // setTimeout(() => setApiCall(0), 5000)
    }

    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    returnFilterVal?.returnFilter,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { returnList = [], isLoading } = useSelector((state) => state.order);
  const { returnStatus = [], isreturnStatusLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = returnList;
  const orderData = listOrders?.orderList;

  let LsSts = 'New';
  const [allCnts, setallCnts] = useState(0);
  const [pendingCnts, setpendingCnts] = useState(0);
  const [authorizedCnts, setauthorizedCnts] = useState(0);
  const [rejectedCnts, setrejectedCnts] = useState(0);
  const [receivedCnts, setreceivedCnts] = useState(0);
  const [approvedCnts, setapprovedCnts] = useState(0);

  const stsCounts = returnStatus?.data;
  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

  useEffect(() => {
    statusArr = [];
    let allCounts = 0;

    if (stsCounts) {
      Object.keys(stsCounts).forEach(function (key) {
        let Sts = key.replace(/\s+/g, '');
        let Counts = stsCounts[key];
        allCounts += Number(stsCounts[key]);
        const countsArr = { [Sts]: Counts };
        statusArr.push(countsArr);
      });
      statusArr.push({ allCounts: allCounts });
    }
    setallCnts(getValue(statusArr, 'allCounts'));
    setpendingCnts(getValue(statusArr, 'Pending'));
    setauthorizedCnts(getValue(statusArr, 'Authorized'));
    setrejectedCnts(getValue(statusArr, 'Rejected'));
    setreceivedCnts(getValue(statusArr, 'Recevied'));
    setapprovedCnts(getValue(statusArr, 'Approved'));
  }, [stsCounts]);

  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: { id: 'sort.date_asc' } },
    { key: 'date', order: SORTING_ORDER.DESC, label: { id: 'sort.date_desc' } },
    {
      key: 'status',
      order: SORTING_ORDER.ASC,
      label: { id: 'sort.status_asc' },
    },
    {
      key: 'status',
      order: SORTING_ORDER.DESC,
      label: { id: 'sort.status_desc' },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    setApiCall(0);
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
    setApiCall(0);
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
    setApiCall(0);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
    setApiCall(0);
  };

  const handleSelectStatus = (selectedGroup) => {
    setreturnFilterValue({ returnFilter: selectedGroup?.value });
    localStorage.setItem('returnFilter', JSON.stringify(selectedGroup));
    setSelectedStatus(selectedGroup);
    setApiCall(0);
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const AdvanceFilterOnClick = (filterStatus) => {
    if (filterStatus === false) {
      setActiveFilter(true);
    } else {
      setActiveFilter(false);
    }
  };

  // const orderStatus = [
  //   {
  //     label: "Return Status",
  //     options: [
  //       {   label: 'All Return Orders', value: 'All'},
  //       {   label: 'Pending', value: 'pending'},
  //       {   label: 'Authorized', value: 'Authorized'},
  //       {   label: 'Rejected', value: 'Rejected'},
  //       {   label: 'Recevied', value: 'Recevied'},
  //       {   label: 'Approved', value: 'Approved'},

  //     ]
  //   }

  // ]
  let isStatusFilter = JSON.parse(localStorage.getItem('returnFilter'));
  if (returnFilterVal?.returnFilter === '') {
    isStatusFilter = '';
  }
  const AdvanceFilterClear = (filterStatus) => {
    setActiveFilter(false);
    localStorage.removeItem('returnFilter');
    setreturnFilterValue({ returnFilter: 'All' });
    setApiCall(0);
  };

  useEffect(() => {
    if (returnList?.code === 100) {
      // setApiCall(1);
      //setTimeout(() => setApiCall(0), 5000)
    } else {
    }
  }, [listOrders]);
  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="justify-content-between align-items-center dh-TabRow ">
        <Col md={7} sm={6} xs={12} className=" overflow-y-hidden">
          <div className="align-items-center">
            <Nav
              tabs
              className="nav-tabs-custom card-header-tabs  flex-nowrap scrollNav dh-custom-tab"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === 'All',
                  })}
                  onClick={() => {
                    toggleCustom('All');
                  }}
                >
                  <span className=" d-sm-block " nowrap="nowrap">
                    {' '}
                    ALL
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === 'Pending',
                  })}
                  onClick={() => {
                    toggleCustom('Pending');
                  }}
                >
                  <span className="d-sm-block">PENDING</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === 'Authorized',
                  })}
                  onClick={() => {
                    toggleCustom('Authorized');
                  }}
                >
                  <span className="d-sm-block"> AUTHORIZED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === 'Received',
                  })}
                  onClick={() => {
                    toggleCustom('Received');
                  }}
                >
                  <span className=" d-sm-block">RECEIVED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === 'Approved',
                  })}
                  onClick={() => {
                    toggleCustom('Approved');
                  }}
                >
                  <span className="d-sm-block">APPROVED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === 'Rejected',
                  })}
                  onClick={() => {
                    toggleCustom('Rejected');
                  }}
                >
                  <span className=" d-sm-block"> REJECTED</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>

        <Col md="auto" sm="auto" xs="auto">
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>

          {/* <Col md={5} className="mb-2">
        <span  className="textAlignRight">
         <a  onClick={() =>{ AdvanceFilterOnClick(ActiveFilter)}} className="filterTxt " >Filter 
         {isStatusFilter !== ''|| ActiveFilter === true ? <i className="bx bx-filter-alt"></i> :  <i className="bx bx-filter-alt"></i> }
         </a>
         </span>
        
<span className="textAlignRight">
<a  onClick={() =>{ AdvanceFilterClear(ActiveFilter)}} style={{color:"rgb(22, 139, 189)",cursor:"pointer",paddingRight:"5px"}}>Clear Filter </a>
</span>
        </Col> */}
        </Col>

        {/* {ActiveFilter === true ? (<><Row className="align-items-end">
        <Col lg={2} md={6}>
            <div className="mb-3">
              <label htmlFor="choices-single-default" className="form-label font-size-13 text-muted">Status</label>
              <Select
                value={isStatusFilter}
                onChange={handleSelectStatus}
                options={orderStatus}
                defaultValue={isStatusFilter}
                classNamePrefix="select2-selection"
              />
            </div>
          </Col></Row></>):null} */}
      </Row>

      <div className=" dt-table">
        {isLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <div class="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>SI.No</th>

                    <th>Return No</th>
                    <th>Country</th>
                    <th>Sku</th>
                    <th>Order Reference</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {(orderData || []).map((order, index) => {
                  pageIndex++;
                  const { entity_id: orderid } = order;
                  let order_id = order.entity_id;
                  let split = 'All';
                  //  console.log("order_id----------",order_id);
                  let cdate = order.ReturnDate,
                    orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                    orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                  let stsBtn = 'bg-soft-dark text-dark';
                  if (
                    order.Status.toLowerCase() === 'pending' ||
                    order.Status === 'Cancel Pending'
                  ) {
                    stsBtn = 'bg-soft-warning text-dark';
                    LsSts = 'Pending';
                  } else if (order.Status.toLowerCase() === 'authorized') {
                    stsBtn = 'bg-primary';
                    LsSts = 'Authorized';
                  } else if (order.Status.toLowerCase() === 'received') {
                    stsBtn =
                      'bg-soft-success border border-success text-success';
                    LsSts = 'Received';
                  } else if (order.Status === 'return_scheduled') {
                    stsBtn = 'bg-primary';
                    LsSts = 'Return scheduled';
                  } else if (order.Status === 'return_initated') {
                    stsBtn = 'bg-primary';
                    LsSts = 'Return initiated';
                  } else if (order.Status === 'return_pickup_fail') {
                    stsBtn = 'bg-primary';
                    LsSts = 'Return Pickup Failed';
                  } else if (order.Status === 'return_drop_success') {
                    stsBtn =
                      'bg-soft-success border border-success text-success';
                    LsSts = 'Received';
                  } else if (order.Status === 'out_for_return_pickup') {
                    stsBtn = 'bg-primary';
                    LsSts = 'Return out for pickup';
                  } else if (order.Status.toLowerCase() === 'approved') {
                    LsSts = 'Approved';
                    stsBtn = 'bg-success';
                  } else if (
                    order.Status.toLowerCase() === 'rejected' ||
                    order.Status.toLowerCase() === 'canceled' ||
                    order.Status === 'return_canceled' ||
                    order.Status === 'pending_payment' ||
                    order.Status === 'cancel'
                  ) {
                    if (order.Status.toLowerCase() === 'rejected') {
                      LsSts = 'Rejected';
                    } else {
                      LsSts = 'Canceled';
                    }
                    stsBtn = 'bg-soft-danger text-danger';
                  } else {
                    LsSts = `${order.Status}`;
                  }
                  return (
                    <tbody key={order?.ReturnId}>
                      <tr>
                        <th scope="row">{pageIndex}</th>

                        <td nowrap="nowrap">
                          <b>{order.ReturnNo}</b>
                          <span className="highlightInfo2 ">
                            {orderDt} {orderTime}
                          </span>
                        </td>
                        <td>{order?.country.toUpperCase()}</td>

                        <td>{order?.ItemSku}</td>
                        <td>{order?.OrderId}</td>
                        <td>{order?.OrderQty}</td>
                        <td>
                          <Badge className={` ${stsBtn}`}>{LsSts}</Badge>
                        </td>

                        <td>
                          <a
                            href={`returnorders/${order?.ReturnId}/${order?.OrderId}`}
                            type="button"
                            className="btn btn-sm btn-outline-warning waves-effect waves-light"
                          >
                            {' '}
                            <i className="mdi mdi-eye-outline"></i>{' '}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            </div>
            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-dark">
                    {limitValue.limit} Items{' '}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>

                  <th>Return No</th>
                  <th>Country</th>
                  <th>Sku</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default ReturnOrderList;
